<template>
  <div>
    <HeaderPanel
      title="Business Hour"
      :hasFilter="false"
      :filter="filter"
      placeholder="Search Name, Telephone"
      :hasSearch="false"
      :hasDropdown="false"
      routerPath="/setting/business-hour/0"
    >
    </HeaderPanel>

    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <b-table
        responsive
        striped
        hover
        :fields="fields"
        :items="items"
        :busy="isLoading"
        show-empty
        empty-text="No matching records found"
      >
        <template v-slot:cell(active)="data">
          <div :class="[data.item.active == 1 ? 'text-success' : 'text-error']">
            {{ $helper.status(data.item.active) }}
          </div>
        </template>
        <template v-slot:cell(action)="{ item }">
          <div class="d-flex justify-content-center">
            <router-link :to="'/setting/business-hour/' + item.id">
              <b-button variant="link" class="text-warning px-1 py-0">
                <font-awesome-icon icon="pencil-alt" title="Edit" />
              </b-button>
            </router-link>
            <b-button
              variant="link"
              class="text-error px-1 py-0"
              @click="removeItems(item.id)"
            >
              <font-awesome-icon icon="trash-alt" title="delete" />
            </b-button>
          </div>
        </template>

        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
      </b-table>
      <Pagination
        @handleChangeTake="handleChangeTake"
        :pageOptions="pageOptions"
        :filter="filter"
        :rows="rows"
        take="pageSize"
        @pagination="pagination"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      isLoading: false,
      fields: [
        {
          key: "day_name",
          label: "Working days",
          thClass: "w-2",
        },
        {
          key: "time_start",
          label: "Opening time",
          thClass: "w-5",
        },
        {
          key: "time_end",
          label: "Closing time",
          thClass: "w-2",
        },
        {
          key: "active",
          label: "Status",
          class: "w-1",
        },
        {
          key: "action",
          label: "",
          class: "w-1",
        },
      ],
      items: [],
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      filter: {
        page: 1,
        pageSize: 10,
      },
    };
  },

  async mounted() {
    await this.getList();
  },
  watch: {},
  destroyed() {},
  methods: {
    clearValue() {},
    async getList() {
      this.isLoading = true;

      const res = await this.axios.post(
        `/setting/ticket/listbusinesshour`,
        this.filter
      );
      if (res.data.result === 1) {
        this.items = res.data.detail.data;
        this.rows = res.data.detail.total;

        this.isLoading = false;
      }
    },

    handleChangeTake(value) {
      this.filter.pageSize = value;
      this.getList();
    },
    pagination(page) {
      this.filter.page = page;
      this.getList();
    },

    clearFilter() {
      this.filter = {
        search: "",
        page: 1,
        pageSize: 10,
      };
    },
    async removeItems(id) {
      this.confirmAlert({ message: "Confirm Business Hour ?" }).then(
        async (value) => {
          if (value) {
            this.$bus.$emit("showLoading");
            const res = await this.axios.delete(
              `/setting/ticket/businesshour/${id}`
            );
            this.$bus.$emit("hideLoading");
            if (res.data.result) this.successAlert(), this.getList();
            else this.errorAlert(res.data.message);
          }
        }
      );
    },
  },
};
</script>

<style scoped></style>
